/* reset styles */
html {
  box-sizing: border-box;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
}

body {
  color: #000;
  background: #fff;
  font: 15px/1.5 "Manrope", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: 0;
}

// google map fix
.gm-style img {
  max-width: none;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: inherit;
  font-weight: bold;
  margin: 0 0 0.5em;
  color: inherit;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 40px;
}

p {
  margin: 0 0 1em;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #524e4e;
  }
}

a:hover,
a:focus {
  text-decoration: none;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #999;
  padding: 0.4em 0.7em;
}
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: #000;
}
input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
}
input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #999;
}
input[type="text"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999;
}
input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999;
}
input[type="text"].placeholder,
input[type="tel"].placeholder,
input[type="email"].placeholder,
input[type="search"].placeholder,
input[type="password"].placeholder,
textarea.placeholder {
  color: #999;
}

select {
  -webkit-border-radius: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}

a,
button,
input[type="submit"] {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

/* common css used in all the pages */
.visible-mobile {
  display: none;
}

body.nav-active {
  overflow: hidden;
  height: 100vh;
}

#root {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* button outline styles */
.btn {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #000000;
  font-size: 20px;
  line-height: 1.5;
  font-family: "Overpass Mono", monospace;
  padding: 15px 20px;
  text-align: center;

  &:hover {
    border-color: #524e4e;
    color: #524e4e;
  }

  /* button solid background styles */
  &.btn-solid {
    background: #000;
    color: #fff;

    &:hover {
      background: #524e4e;
      color: #fff;
    }
  }
}

/* link text styles */
.link-text {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;

  .fas {
    margin: 0 0 0 10px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}

@media (max-width: 1023px) {
  .visible-mobile {
    display: block;
  }

  .hidden-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
